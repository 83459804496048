import Footer from './Footer';
import Header from './Header';
import MobileNavigation from './MobileNavigation';

export default function Support() {
  return (
    <>
      <main className="">
        <Header />
        <MobileNavigation />

        <section className="bg-white">
          <div className="grid items-center grid-cols-1 gap-1 row lg:grid-cols-2">
            <div className="mb-6">
              <h1 className="mr-8 medium">Frequently Asked Questions</h1>
            </div>

            <div className="grid grid-cols-1 gap-3 xl:grid-cols-2">
              <div className="border-l border-gray-100 p-5 mb-1 bg-gray-100 rounded-[16px]">
                <h2 className="">Support</h2>
                <a href="mailto:customersupport@iqpro.com" className="has-icon email-link">
                  customersupport@iqpro.com
                </a>
                <a href="tel:8883661325" className="has-icon phone-link">
                  (888) 366-1325
                </a>
              </div>
              <div className="border-l border-gray-200 p-5 bg-gray-100 rounded-[16px]">
                <h2 className="">Sales</h2>
                <a href="tel:8003860711" className="has-icon phone-link">
                  (800) 386-0711
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white">
          <div className="row grid grid-cols-[60px_1fr]">
            <div className="fancy-number">1</div>
            <div className="item">
              <h2 className="medium">How do I log into iQ Pro+?</h2>
              <p className="light">
                You can log into iQ Pro+{' '}
                <a href="https://app.iqpro.com/" className="underline">
                  here
                </a>
                .
              </p>
            </div>
          </div>

          <div className="row grid grid-cols-[60px_1fr]">
            <div className="fancy-number">2</div>
            <div className="item">
              <h2 className="medium">How do I reset my password?</h2>
              <p className="light">
                If you have forgotten your login credentials, please utilize the “Forgot your
                password?” links on the{' '}
                <a href="https://app.iqpro.com/" className="underline">
                  login page
                </a>
                .
              </p>
            </div>
          </div>

          <div className="row grid grid-cols-[60px_1fr]">
            <div className="fancy-number">3</div>
            <div className="item">
              <h2 className="medium">How can I access iQ Pro+?</h2>
              <p className="light">
                iQ Pro is available on any device with access to a web browser, and will utilize the
                native connectivity of that device (Internet, WiFi, Cellular, etc.)
              </p>
            </div>
          </div>

          <div className="row grid grid-cols-[60px_1fr]">
            <div className="fancy-number">4</div>
            <div className="item">
              <h2 className="medium">What transaction types does iQ Pro+ support?</h2>
              <p className="light">
                iQ Pro+ supports Sales, Pre-Authorizations, Card Verifications, Full and Partial
                Refunds and Blind Credits.
              </p>
            </div>
          </div>

          <div className="row grid grid-cols-[60px_1fr]">
            <div className="fancy-number">5</div>
            <div className="item">
              <h2 className="medium">Why did my transaction decline?</h2>
              <p className="light">
                Transactions are approved or declined by the issuing bank of the card. iQ Pro+ will
                display the full, detailed transaction response that includes the decline code from
                the issuer. Please contact us if you need more information on a transaction status.
              </p>
            </div>
          </div>

          <div className="row grid grid-cols-[60px_1fr]">
            <div className="fancy-number">6</div>
            <div className="item">
              <h2 className="medium">What fields are required to process Level II/III?</h2>
              <p className="light">
                The minimum required fields to process a card transaction include the payment
                information (card number and expiration date) and the cardholder first and last
                name. All Level II and III fields are submitted automatically on qualified card
                types without manual entry.
              </p>
            </div>
          </div>

          <div className="row grid grid-cols-[60px_1fr]">
            <div className="fancy-number">7</div>
            <div className="item">
              <h2 className="medium">Can I import my customer data into iQ Pro+?</h2>
              <p className="light">
                In many cases, iQ Pro+ can accept an import of customer data (including secured
                payment data) from other gateways and software. Please{' '}
                <a href="tel:8883661325" className="underline">
                  contact us
                </a>{' '}
                for more information.
              </p>
            </div>
          </div>

          <div className="row grid grid-cols-[60px_1fr]">
            <div className="fancy-number">8</div>
            <div className="item">
              <h2 className="medium">How can I export my transactions for reporting?</h2>
              <p className="light">
                iQ Pro+ supports export of transaction reporting through the universal format of a
                CSV file for easy upload into other software for reporting and reconciliation
                purposes.
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
}
