import Footer from '../Footer';
import Header from '../Header';
import customLineItems from '../img/icon-custom-line-items.svg';
import proRated from '../img/icon-prorated-billing.svg';
import advancedFiltering from '../img/icon-advanced-filtering.svg';
import setAndForget from '../img/icon-set-it-forget-it.svg';
import recurringBillingImage from '../img/RecurringBillingImage.png';
import recurringReporting from '../img/RecurringReporting.png';
import customerRecurring from '../img/CustomerRecurring.png';
import recurringInvoices from '../img/recurringInvoices.png';
import MobileNavigation from '../MobileNavigation';
import background from '../img/softwarePartners.png';
import GetStarted from './GetStarted';
import { Link } from 'react-router-dom';
import HeroFeatures from './HeroFeatures';

export default function RecurringBilling() {
  return (
    <>
      <main>
        <Header />
        <MobileNavigation />
        <HeroFeatures
          title="RECURRING BILLING"
          primary={
            <>
              Save time - bill <span className="highlight-pink">automatically</span>.
            </>
          }
          secondary="Recurring payments that simplify the billing process."
          img={recurringBillingImage}
          imgAlt="recurring billing demo"
        />
        <section className="bg-white">
          <div className="grid grid-cols-1 gap-4 row sm:grid-cols-2 lg:grid-cols-4">
            <article className="card">
              <figure>
                <img src={customLineItems} alt="card icon" />
              </figure>
              <p className="light">Create and add custom line items</p>
            </article>
            <article className="card">
              <figure>
                <img src={proRated} alt="physical terminal icon" />
              </figure>
              <p className="light">Prorated billing and trial options</p>
            </article>
            <article className="card">
              <figure>
                <img src={advancedFiltering} alt="email icon" />
              </figure>
              <p className="light">Advanced filtering</p>
            </article>
            <article className="card">
              <figure>
                <img src={setAndForget} alt="magic icon" />
              </figure>
              <p className="light">Set it and forget it</p>
            </article>
          </div>
        </section>
        <section className="bg-white">
          <div className="row">
            <p className="text-center large">
              Save time and boost your bottom line by simplifying your payment process using
              Recurring Billing.
            </p>
          </div>
        </section>
        <section className="bg-white">
          <div
            className="row"
            style={{
              borderRadius: '25px',
              backgroundImage: `url(${background})`,
              backgroundSize: '200% 200%',
              backgroundPosition: 'bottom center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="p-4 mx-auto md:p-8">
              <h3 className="!mb-5 text-white large">How does Recurring Billing work?</h3>
              <ul className="my-8 fancy-bullets white">
                <li>
                  <p className="text-white light">
                    Create Subscriptions <span className="font-bold">tailored to your needs</span>{' '}
                    by using custom line items. Use the Invoice generated each billing cycle to
                    track the status of payments within the Subscription.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    You can easily link Subscriptions to a saved or new Customer. Subscriptions
                    appear in the Customer&apos;s record, making it{' '}
                    <span className="font-bold">simple</span> to manage recurring payments.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    Choose the schedule that's <span className="font-bold">right for you</span> with
                    options to bill on a daily, weekly, monthly, quarterly or annual basis.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    Offer <span className="font-bold">flexible billing options</span>, like trial
                    periods and prorated charges. Allow customers to pay using a saved payment
                    method or send them an Invoice automatically each billing cycle.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="row">
            <div className="my-8 callout">
              <p className="large">
                Automating payments can cut down on time spent managing invoices by up to{' '}
                <span className="highlight-pink">80%</span>.
              </p>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="grid items-center grid-cols-1 row lg:grid-cols-2">
            <article className="col-span-1">
              <p className="text-center large lg:text-left">
                Access Subscription details directly from the Subscription or through an associated{' '}
                <span className="highlight-pink">Customer</span>.
              </p>
              <Link
                to="/features/customers"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1">
              <img src={customerRecurring} alt="customer details mockup" />
            </figure>
          </div>
        </section>
        <section className="bg-white">
          <div className="grid items-center grid-cols-1 row lg:grid-cols-2">
            <figure className="hidden col-span-1 lg:block">
              <img src={recurringReporting} alt="Reporting mockup for subscriptions" />
            </figure>
            <article className="col-span-1">
              <p className="text-center large lg:text-left">
                Filter transactions by Subscription ID to view all related transaction details in{' '}
                <span className="highlight-pink">Reporting</span>.
              </p>
              <Link
                to="/features/reporting"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1 lg:hidden">
              <img src={recurringReporting} alt="Reporting mockup for subscriptions" />
            </figure>
          </div>
        </section>
        <section className="bg-white">
          <div className="grid items-center grid-cols-1 row lg:grid-cols-2">
            <article className="col-span-1">
              <p className="text-center large lg:text-left">
                Autogenerate <span className="highlight-pink">Invoices</span> each billing cycle to
                automatically charge Customers or send them for manual payment.
              </p>
              <Link
                to="/features/invoicing"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1">
              <img src={recurringInvoices} alt="mockup of recurring invoices" />
            </figure>
          </div>
        </section>
        <GetStarted />
      </main>
      <Footer />
    </>
  );
}
