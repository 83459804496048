import Footer from '../Footer';
import Header from '../Header';
import MobileNavigation from '../MobileNavigation';
import background from '../img/softwarePartners.png';
import mobileAppImage from '../img/MobileAppHeader.png';
import mobileTerminal from '../img/TerminalMobile.png';
import mobileCustomers from '../img/mobileCustomers.png';
import { Link } from 'react-router-dom';
import HeroFeatures from './HeroFeatures';
import GetStarted from './GetStarted';
import cc from '../img/icon-accept-card-and-ach.svg';
import customize from '../img/icon-customize-to-brand.svg';
import receipts from '../img/icon-globe.svg';
import fullyIntegrated from '../img/icon-card-scan.svg';

export default function MobileApp() {
  return (
    <>
      <main>
        <Header />
        <MobileNavigation />
        <HeroFeatures
          title="MOBILE APP"
          primary={
            <>
              <span className="font-semibold text-[#C0F]">iQ Pro+</span> at your fingertips.
            </>
          }
          secondary="Process payments from your phone or tablet."
          img={mobileAppImage}
          imgAlt="A hand holding a phone with the iQPro+ app on screen"
        />
        <section className="bg-white">
          <div className="grid grid-cols-1 gap-4 row sm:grid-cols-2 lg:grid-cols-4">
            <article className="card">
              <figure>
                <img src={cc} alt="card icon" />
              </figure>
              <p className="light">Accept Card and ACH payments</p>
            </article>
            <article className="card">
              <figure>
                <img src={customize} alt="gear icon" />
              </figure>
              <p className="light">Available on iOS and Android</p>
            </article>
            <article className="card">
              <figure>
                <img src={receipts} alt="email icon" />
              </figure>
              <p className="light">Take secure mobile payments on the go</p>
            </article>
            <article className="card">
              <figure>
                <img src={fullyIntegrated} alt="tree icon" />
              </figure>
              <p className="light">Scan cards to seamlessly process transactions</p>
            </article>
          </div>
        </section>
        <section className="bg-white">
          <div
            className="row"
            style={{
              borderRadius: '25px',
              backgroundImage: `url(${background})`,
              backgroundSize: '200% 200%',
              backgroundPosition: 'bottom center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="p-4 mx-auto md:p-8">
              <h3 className="text-white large">What is the iQ Pro+ Mobile App?</h3>
              <p className="text-white light">
                The iQ Pro+ Mobile App gives you the power to securely and efficiently collect
                payments from wherever you are.
              </p>
              <ul className="my-8 fancy-bullets white">
                <li>
                  <p className="text-white light">
                    Enjoy the same seamless experience on your phone, tablet or laptop thanks to the
                    iQ Pro+ mobile and web applications. Log in to your gateway on the Mobile App to
                    safely process transactions or quickly add, manage and charge customers.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    Receive Level II and Level III rates on qualifying transactions.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="grid items-center grid-cols-1 row lg:grid-cols-2">
            <article className="col-span-1">
              <p className="text-center large lg:text-left">
                Use the <span className="highlight-pink">Terminal</span> to process transactions
                wherever you have internet.
              </p>
              <Link
                to="/features/terminal"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1">
              <img src={mobileTerminal} alt="mockup of a mobile terminal" />
            </figure>
          </div>
        </section>
        <section className="bg-white">
          <div className="grid items-center grid-cols-1 row lg:grid-cols-2">
            <figure className="hidden col-span-1 lg:block">
              <img src={mobileCustomers} alt="mockup of a view of customers on mobile" />
            </figure>
            <article className="inline-flex flex-col items-start justify-center gap-8">
              <p className="text-center large lg:text-left">
                Add, manage and charge <span className="highlight-pink">Customers</span> from your
                device.
              </p>
              <Link
                to="/features/customers"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1 lg:hidden">
              <img src={mobileCustomers} alt="mockup of a view of customers on mobile" />
            </figure>
          </div>
        </section>

        <GetStarted />
      </main>
      <Footer />
    </>
  );
}
