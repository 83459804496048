import TerminalImg from '../img/TerminalImage.png';
import background from '../img/softwarePartners.png';
import MobileTerminal from '../img/TerminalMobile.png';
import physicalTerminal from '../img/PhysicalTerminalVirtual.png';
import Header from '../Header';
import MobileNavigation from '../MobileNavigation';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import HeroFeatures from './HeroFeatures';
import GetStarted from './GetStarted';
import cc from '../img/icon-credit-card-or-ach.svg';
import combine from '../img/icon-combine-with-terminals.svg';
import taxCalc from '../img/icon-automatic-tax-calculation.svg';
import receipts from '../img/icon-receipts.svg';
export default function Terminal() {
  return (
    <>
      <main>
        <Header />
        <MobileNavigation />
        <HeroFeatures
          title="TERMINAL"
          primary={
            <>
              Process transactions from <span className="highlight-pink">anywhere</span>.
            </>
          }
          secondary="Securely take payments on any device with an internet connection."
          img={TerminalImg}
          imgAlt="Hands holding tablet with terminal design"
        />
        <section className="bg-white">
          <div className="grid grid-cols-1 gap-4 row sm:grid-cols-2 lg:grid-cols-4">
            <article className="card">
              <figure>
                <img src={cc} alt="card icon" />
              </figure>
              <p className="light">Accept Card and ACH payments</p>
            </article>
            <article className="card">
              <figure>
                <img src={combine} alt="physical terminal icon" />
              </figure>
              <p className="light">Combine with physical terminals</p>
            </article>
            <article className="card">
              <figure>
                <img src={receipts} alt="email icon" />
              </figure>
              <p className="light">Send receipts via email</p>
            </article>
            <article className="card">
              <figure>
                <img src={taxCalc} alt="magic icon" />
              </figure>
              <p className="light">Automatic tax calculation</p>
            </article>
          </div>
        </section>
        <section className="bg-white">
          <div className="row">
            <div className="grid justify-center grid-cols-1">
              <p className="large">Boost your bottom line with seamless payments.</p>
            </div>
            <div className="grid grid-cols-1">
              <p className="light">
                If you’re accepting Business to Business (B2B) transactions, you can qualify for the{' '}
                <span className="highlight-pink">
                  <i>lowest possible rates</i>{' '}
                </span>
                on card not present (CNP) transactions.
              </p>
              <p>
                On most gateways, this would require manually submitting between 5 and 19 additional
                pieces of data with each transaction.
              </p>
            </div>

            <section className="my-8 callout">
              <p className="large">
                iQ Pro+{' '}
                <span className="highlight-pink">
                  <i>automatically</i>{' '}
                </span>
                captures and submits this information with each transaction, saving you valuable
                time and money.
              </p>
            </section>
            <section className="grid grid-cols-1">
              <p className="medium">Always have a backup plan.</p>
              <p className="medium light">
                The iQ Pro+ terminal turns any device with internet access into a trustworthy
                payment option if you need it.
              </p>
            </section>
          </div>
        </section>

        <section className="bg-white">
          <div
            className="row"
            style={{
              borderRadius: '25px',
              backgroundImage: `url(${background})`,
              backgroundSize: '200% 200%',
              backgroundPosition: 'bottom center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="p-4 mx-auto md:p-8">
              <h3 className="text-white large">How does the Terminal work?</h3>
              <p className="text-white light">
                The iQ Pro+ Terminal is a fully integrated solution that gives you the power to
                process transactions online.
              </p>
              <ul className="my-8 fancy-bullets white">
                <li>
                  <p className="text-white light">
                    Manually enter new payment information, swipe with a compatible device or charge
                    a customer's saved payment method.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    Add custom fields to personalize the payment experience and take efficiency to
                    the next level.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="grid items-center grid-cols-1 row lg:grid-cols-2">
            <article className="col-span-1">
              <p className="text-center large lg:text-left">
                Utilize a compatible <span className="highlight-pink">Physical Terminal</span> to
                accept card present transactions.
              </p>
              <Link
                to="/features/physicalTerminals"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1">
              <img
                src={physicalTerminal}
                alt="Physical terminal with a card and how two terminals show up on iQPro+"
              />
            </figure>
          </div>
        </section>
        <section className="bg-white">
          <div className="grid items-center grid-cols-1 row lg:grid-cols-2">
            <figure className="hidden col-span-1 lg:block">
              <img src={MobileTerminal} alt="Mobile terminal screenshot" />
            </figure>
            <article className="col-span-1">
              <p className="text-center large lg:text-left">
                Access iQ Pro+ anywhere with the <span className="highlight-pink">Mobile App</span>.
              </p>
              <Link
                to="/features/mobileApp"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1 lg:hidden">
              <img src={MobileTerminal} alt="Mobile terminal screenshot" />
            </figure>
          </div>
        </section>
        <GetStarted />
      </main>
      <Footer />
    </>
  );
}
