import * as React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="flex items-start justify-center gap-16 px-16 pt-24 pb-12">
      <div className="flex justify-between items-end flex-[1_0_0]">
        <div className="flex flex-col items-start justify-center gap-2">
          <p className="small">&copy; {currentYear} iQ Pro</p>
          <p className="small">iQ Pro is a registered service mark of BASYS Processing. </p>
          <p className="small">All rights reserved.</p>
        </div>
        <div className="flex items-center gap-6">
          <a href="https://basyspro.com/privacy-policy/">
            <p className="small">Privacy Policy</p>
          </a>
          <Link to="/terms-of-service">
            <p className="small">Terms of Service</p>
          </Link>
        </div>
      </div>
    </footer>
  );
}
