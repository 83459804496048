import logo from '../img/iQPro+ Logo.png';
import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from '@gsap/business';
import DrawSVGPlugin from '@gsap/business/DrawSVGPlugin';
import { Link } from 'react-router-dom';

gsap.registerPlugin(useGSAP);

gsap.registerPlugin(DrawSVGPlugin);

export default function Header() {
  const container = useRef();
  useGSAP(
    () => {
      let loginMenu = gsap.fromTo(
        '.dropdown-login',
        {
          opacity: 0,
          scale: 0
        },
        { opacity: 1, scale: 1, duration: 0.2, paused: true, transformOrigin: 'left top' }
      );
      let loginButton = document.getElementById('loginButton');
      let dropdown3 = document.getElementById('login');
      loginButton.addEventListener('mouseenter', () => loginMenu.play());
      dropdown3.addEventListener('mouseleave', () => loginMenu.reverse());
    },
    { scope: container }
  );
  return (
    <section ref={container} className="max-[1000px]:hidden">
      <div className="row nav-row">
        <header className="flex self-stretch justify-between items-center px-2 py-2 rounded-[999px] bg-white box-shadow">
          <a className="logo" href="/onboarding-features">
            <img src={logo} alt="iQ Pro+ Logo" />
          </a>
          <nav className={`flex items-center justify-center h-auto gap-0`}>
            <Link
              to="/onboarding-features"
              className="button button-secondary has-arrow"
              id="featuresButton"
            >
              Features
            </Link>
            <Link
              to="/onboarding-training"
              className="button button-secondary has-arrow"
              id="resourcesButton"
            >
              Training Videos
            </Link>
            <div className="dropdown" id="login">
              <button className="button button-secondary has-arrow" id="loginButton">
                Developer Docs
              </button>
              <div className="grid grid-cols-1 dropdown-content dropdown-login">
                <a
                  className="subnav-item"
                  href="https://sandbox.developer.basyspro.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="sub-nav-header">Sandbox Docs</p>
                </a>
                <a
                  className="subnav-item"
                  href="https://developer.basyspro.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="sub-nav-header">Prod Docs</p>
                </a>
              </div>
            </div>
            <Link to="/onboarding-conversion" className="button button-secondary has-arrow">
              Support
            </Link>
          </nav>
          <Link
            to="/onboarding-sign-in"
            className="button button-primary has-arrow has-arrow-white"
            id="navTopButtonNormal"
          >
            Login
          </Link>
        </header>
      </div>
    </section>
  );
}
