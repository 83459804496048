import Footer from '../Footer';
import Header from '../Header';
import MobileNavigation from '../MobileNavigation';
import simplePayImage from '../img/SimplePayImage.png';
import background from '../img/softwarePartners.png';
import HeroFeatures from './HeroFeatures';
import GetStarted from './GetStarted';
import qr from '../img/icon-create-qr-code.svg';
import customize from '../img/icon-customize-to-brand.svg';
import noCoding from '../img/icon-no-coding-necessary.svg';
import streamlined from '../img/icon-streamlined-experience.svg';

export default function SimplePay() {
  return (
    <>
      <main>
        <Header />
        <MobileNavigation />

        <HeroFeatures
          title="SIMPLE PAY"
          primary={
            <>
              <span className="highlight-pink">Simplify</span> collecting payments.
            </>
          }
          secondary="Customize and send your customers a pre-built payment form."
          img={simplePayImage}
          imgAlt="Coffee Bean Original mockup of a Simple Pay payment form."
        />
        <section className="bg-white">
          <div className="grid grid-cols-1 gap-4 row sm:grid-cols-2 lg:grid-cols-4">
            <article className="card">
              <figure>
                <img src={qr} alt="card icon" />
              </figure>
              <p className="light">Create payment link</p>
            </article>
            <article className="card">
              <figure>
                <img src={customize} alt="gear icon" />
              </figure>
              <p className="light">Customize to match your brand</p>
            </article>
            <article className="card">
              <figure>
                <img src={noCoding} alt="tool icon" />
              </figure>
              <p className="light">Ready-made page with no coding necessary</p>
            </article>
            <article className="card">
              <figure>
                <img src={streamlined} alt="click icon" />
              </figure>
              <p className="light">Streamline the payment experience</p>
            </article>
          </div>
        </section>
        <section className="bg-white">
          <div
            className="row"
            style={{
              borderRadius: '25px',
              backgroundImage: `url(${background})`,
              backgroundSize: '200% 200%',
              backgroundPosition: 'bottom center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="p-4 mx-auto md:p-8">
              <h3 className="!mb-5 text-white large">What is Simple Pay?</h3>
              <p className="text-white light">
                Simple Pay allows businesses to accept Customer payments through a secure hosted
                payment page.
              </p>
              <ul className="my-8 fancy-bullets white">
                <li>
                  <p className="text-white light">
                    Collect payments through a link embedded on your website or by sending Customers
                    a direct link.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    Customize your Simple Pay page colors and fonts to align with your brand.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    No coding skills required - we'll handle everything for you.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <GetStarted />
      </main>
      <Footer />
    </>
  );
}
