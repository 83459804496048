import MobileNavigation from './MobileNavigation';
import Header from './Header';
import Footer from './Footer';
import BasysIQ from './img/Basys IQ.png';
import IQPro from './img/iQ Pro.png';
import IQProPlus from './img/iQPro+ Logo.png';

export default function SignIn() {
  return (
    <>
      <MobileNavigation />
      <main className="h-full w-full px-[200px] pt-[30px] pb-16 flex-col justify-start items-center gap-[75px] inline-flex">
        <Header />
        <section className="h-[313px] max-[1270px]:h-auto justify-start items-start gap-[75px] inline-flex  max-[1270px]:grid">
          <article className="w-full h-full p-6 rounded-[10px] shadow border-2 border-[#4452a3] flex-col justify-center items-center gap-[50px] inline-flex">
            <img className="w-[308.21px] h-[85px]" src={BasysIQ} alt="Basys IQ logo" />
            <a
              className="h-9 px-4 py-2 bg-[#4452a3] rounded-[20px] justify-center items-center gap-2 inline-flex"
              href="https://basyspro.net/Default.aspx"
              target="_blank"
              rel="noreferrer"
            >
              <span className="text-white text-[15px] font-medium font-customFont">Login</span>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2 left-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </a>
          </article>
          <article className="w-full h-full p-6 rounded-[10px] shadow border-2 border-[#4452a3] flex-col justify-center items-center gap-[50px] inline-flex">
            <img src={IQPro} alt="IQ Pro logo" />
            <a
              className="h-9 px-4 py-2 bg-[#4452a3] rounded-[20px] justify-center items-center gap-2 inline-flex"
              href="https://app.basysiqpro.com/auth"
              target="_blank"
              rel="noreferrer"
            >
              <span className="text-white text-[15px] font-medium font-customFont">Login</span>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2 left-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </a>
          </article>
          <article className="w-full h-full p-6 rounded-[10px] shadow border-2 border-[#4452a3] flex-col justify-center items-center gap-[50px] inline-flex">
            <img className="w-[238.16px] h-[85px]" src={IQProPlus} alt="IQ Pro + logo" />
            <a
              className="h-9 px-4 py-2 bg-[#4452a3] rounded-[20px] justify-center items-center gap-2 inline-flex"
              href="https://app.iqpro.com/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="text-white text-[15px] font-medium font-customFont">Login</span>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2 left-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </a>
          </article>
        </section>
      </main>
      <Footer />
    </>
  );
}
