import React from 'react';
import '../BetaFeatures/BetaFeatures.scss';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Navbar from './Navbar';
import Footer from './Footer';
import backgroundFront from '../img/front.png';
import DesktopPhoneView from '../img/frontImage.png';
import search from '../BetaFeatures/assets/search-icon.svg';
import customer from '../BetaFeatures/assets/customer-vt.png';
import FPCustomer from '../BetaFeatures/assets/fp-customer.png';
import BetaCustomer from '../BetaFeatures/assets/beta-customer.png';
import MobileBetaCustomer from '../BetaFeatures/assets/mobile-beta-customer.png';
import DarkMode from '../BetaFeatures/assets/dark-mode.png';
import Feedback from '../BetaFeatures/assets/feedback.png';
import SettlementTotals from '../BetaFeatures/assets/settlements-totals.png';
import BatchOverview from '../BetaFeatures/assets/batch-overview.png';
import ExportOptions from '../BetaFeatures/assets/export-options.png';
import GatewaySwitch from '../BetaFeatures/assets/gateway-switch.png';
import recurringBilling from './assets/recurringBilling.png';
import star from '../img/icon-decoration-star.svg';
import background from '../img/softwarePartners.png';
import MobileNavigation from './MobileNavigation';

const BetaFeatures = () => {
  return (
    <Grid>
      <MobileNavigation />
      <Grid sx={{ width: '100%' }}>
        {/* Hero Section */}
        <section
          className="h-[100vh]"
          style={{
            background: `url(${backgroundFront}) -1.731px / 100% 100% no-repeat`
          }}
        >
          <Navbar />
          <div className="row hero-row">
            <article className="hero-left">
              <h1>
                Accept Payments.
                <span className="oblique">Quickly. Simply. Securely.</span>
              </h1>
            </article>
            <figure className="cols-span-2">
              <img src={DesktopPhoneView} alt="iQ Pro+ on desktop and mobile" />
            </figure>
          </div>
        </section>

        {/* Content Start */}
        <Grid container direction="column" justifyContent="center">
          <Grid item sm>
            {/* FEATURES */}
            <Grid container justifyContent="center" alignItems="center" rowSpacing={5}>
              <Grid className="">
                <Grid
                  item
                  sx={{
                    mt: { xs: 10, md: 28 },
                    mb: { xs: 5, md: 10 }
                  }}
                >
                  <h2 className="text-center large">Features</h2>
                </Grid>
                <Grid container justifyContent="center" sx={{ mb: { xs: 2, lg: 10 } }}>
                  <List>
                    <Grid
                      container
                      justifyContent="between"
                      direction={{
                        xs: 'column',
                        md: 'row'
                      }}
                    >
                      {/* <Grid item xs={1}></Grid> */}
                      <Grid item xs={8} md={7}>
                        <Grid>
                          <ListItem>
                            <ListItemIcon>
                              <img src={star} alt="star icon" />
                            </ListItemIcon>
                            <h2 className="font-semibold text-[35px] max-[390px]:text-[18px]">
                              Virtual Terminal
                            </h2>
                          </ListItem>
                        </Grid>
                        <Grid>
                          <ListItem>
                            <ListItemIcon>
                              <img src={star} alt="star icon" />
                            </ListItemIcon>
                            <h2 className="font-semibold text-[35px] max-[390px]:text-[18px]">
                              Transaction Reporting & Settlements
                            </h2>
                          </ListItem>
                        </Grid>
                        <Grid>
                          <ListItem>
                            <ListItemIcon>
                              <img src={star} alt="star icon" />
                            </ListItemIcon>
                            <h2 className="font-semibold text-[35px] max-[390px]:text-[18px]">
                              Invoices
                            </h2>
                          </ListItem>
                        </Grid>
                        <Grid>
                          <ListItem>
                            <ListItemIcon>
                              <img src={star} alt="star icon" />
                            </ListItemIcon>
                            <h2 className="font-semibold text-[35px] max-[390px]:text-[18px]">
                              Customers
                            </h2>
                          </ListItem>
                        </Grid>
                      </Grid>
                      <Grid item xs={10} md={5}>
                        <Grid>
                          <ListItem>
                            <ListItemIcon>
                              <img src={star} alt="star icon" />
                            </ListItemIcon>
                            <h2 className="font-semibold text-[35px] max-[390px]:text-[18px]">
                              Mobile App
                            </h2>
                          </ListItem>
                        </Grid>
                        <Grid>
                          <ListItem>
                            <ListItemIcon>
                              <img src={star} alt="star icon" />
                            </ListItemIcon>
                            <h2 className="font-semibold text-[35px] max-[390px]:text-[18px]">
                              Recurring Billing
                            </h2>
                          </ListItem>
                        </Grid>
                        <Grid>
                          <ListItem>
                            <ListItemIcon>
                              <img src={star} alt="star icon" />
                            </ListItemIcon>
                            <h2 className="font-semibold text-[35px] max-[390px]:text-[18px]">
                              Simple Pay
                            </h2>
                          </ListItem>
                        </Grid>
                        <Grid>
                          <ListItem>
                            <ListItemIcon>
                              <img src={star} alt="star icon" />
                            </ListItemIcon>
                            <h2 className="font-semibold text-[35px] max-[390px]:text-[18px]">
                              Physical Terminals
                            </h2>
                          </ListItem>
                        </Grid>
                      </Grid>
                      <Grid item lg={1}></Grid>
                    </Grid>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            {/* Look & Feel */}
            <Grid
              container
              // className="look-and-feel"
              className=""
            >
              <Grid>
                <h2 className="text-center large">Cleaner Look and Feel</h2>
                <Grid container direction="row" justifyContent="center" sx={{ mt: 5 }}>
                  {/* Mobile */}
                  <Grid
                    item
                    xs={9}
                    sx={{
                      mb: 5,
                      display: {
                        xs: 'flex',
                        lg: 'none'
                      }
                    }}
                  >
                    <Grid container>
                      <Grid item>
                        <h2 className="font-bold large">User Focused Design</h2>
                      </Grid>
                      <Grid item>
                        <p className="medium">
                          iQ Pro+ features a polished look while maintaining the workflows you’ve
                          come to expect from your payment platform. A streamlined design creates a
                          quicker and more intuitive experience, giving you more time to focus on
                          your business.
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    lg={5}
                    sx={{
                      display: {
                        xs: 'none',
                        lg: 'block'
                      }
                    }}
                  >
                    <img
                      src={FPCustomer}
                      className="fp-customer"
                      alt="iQ Pro interface with arrow pointing to new iQ Pro+ interface"
                    />
                  </Grid>
                  {/* DESKTOP */}
                  <Grid
                    item
                    lg={4}
                    sx={{
                      display: {
                        xs: 'none',
                        lg: 'flex'
                      }
                    }}
                  >
                    <Grid container>
                      <Grid item>
                        <h2 className="font-bold large">User Focused Design</h2>
                      </Grid>
                      <Grid item>
                        <p className="medium">
                          iQ Pro+ features a polished look while maintaining the workflows you’ve
                          come to expect from your payment platform. A streamlined design creates a
                          quicker and more intuitive experience, giving you more time to focus on
                          your business.
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={1}></Grid>
                  <Grid
                    item
                    xs={10}
                    lg={8}
                    sx={{
                      display: {
                        xs: 'none',
                        md: 'block'
                      }
                    }}
                  >
                    <img
                      src={BetaCustomer}
                      className="beta-customers"
                      alt="iQ Pro Bet customer interface"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    lg={8}
                    sx={{
                      display: {
                        xs: 'block',
                        md: 'none'
                      }
                    }}
                  >
                    <img
                      src={MobileBetaCustomer}
                      className="beta-customers"
                      alt="iQ Pro Bet customer interface"
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" sx={{ mt: 10 }}>
                  <Grid item xs={1} lg={0.5}></Grid>
                  <Grid item xs={10} lg={4.5} sx={{ mb: 5 }}>
                    <Grid container>
                      <Grid item>
                        <h2 className="font-bold large">Dark Mode</h2>
                      </Grid>
                      <Grid item>
                        <p className="medium">
                          iQ Pro+ employs your saved device preferences and automatically detects
                          the use of light or dark mode and carries that theme throughout the
                          application, making it even easier on the eyes.
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs lg={1}></Grid>
                  <Grid item xs={9} lg={4}>
                    <img
                      src={DarkMode}
                      className="dark-mode"
                      alt="Example of iQ Pro+ in dark mode"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Transaction Workflow */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              rowSpacing={5}
              sx={{ pt: 5 }}
            >
              <Grid item>
                <h2 className="!mb-3 text-center large">Transaction Workflow</h2>
              </Grid>
              <Grid item>
                <Grid container justifyContent="center">
                  <Grid item lg></Grid>
                  <Grid item xs={10} lg={9.5}>
                    <Grid
                      container
                      direction="row"
                      rowSpacing={3}
                      alignItems="center"
                      justifyContent="center"
                      className="callout"
                    >
                      <Grid item className="max-[390px]:hidden" xs sm={3} lg={1.5}>
                        <img src={search} className="search" alt="Magnifying glass icon" />
                      </Grid>
                      <Grid item xs={8} sm lg>
                        <h2 className="font-bold large leading-[150%] text-left">
                          Search, locate, and select your saved customer directly from the Virtual
                          Terminal payment screen.
                        </h2>
                      </Grid>
                      <Grid item xs={11} lg={12}>
                        <p className="font-normal medium">
                          Saving your customers in the vault allows for secure use of saved payment
                          information. iQ Pro+ takes that one step further with a streamlined
                          payment process for your customers. Search, locate and select your saved
                          customer directly from the Virtual Terminal payment screen.
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg></Grid>
                </Grid>
              </Grid>
              <Grid item xs={11} md={8} lg={7}>
                <img
                  src={customer}
                  className="customer-vt"
                  alt="Example of searching for a saved customer in the Virtual Terminal"
                />
              </Grid>
              <Grid item>
                <Grid container justifyContent="center">
                  <Grid item xs={11} lg={8}>
                    <h2 className="text-center text-[35px] font-normal leading-[150%]">
                      <strong>All your payment methods are easily found on one screen. </strong>
                      No need to navigate between pages to process a transaction.
                    </h2>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                rowSpacing={5}
                sx={{ mt: 10 }}
              >
                <Grid item xs={1} lg={0.5}></Grid>
                <Grid item xs={10} lg={4.5} sx={{ mb: 5 }}>
                  <Grid container>
                    <Grid item>
                      <h2 className="mb-5 font-bold large">Save time - bill automatically.</h2>
                    </Grid>
                    <Grid item>
                      <p className="medium">
                        Boost your bottom line by simplifying your payment process using Recurring
                        Billing.
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs lg={1.5}></Grid>
                <Grid item xs={9} lg={4}>
                  <img src={recurringBilling} alt="Example of iQ Pro+ with recurring billing" />
                </Grid>
              </Grid>
            </Grid>
            {/* Effective Reporting Options */}
            <Grid
              container
              direction="row"
              sx={{
                mt: 10,
                mb: 4,
                px: 5,
                backgroundImage: `url(${background})`,
                backgroundSize: '200% 200%',
                backgroundPosition: 'bottom center',
                backgroundRepeat: 'no-repeat'
              }}
              className=""
            >
              <Grid item sm={1}></Grid>
              <Grid item sm>
                <Grid sx={{ my: 5 }}>
                  <h2 className="large !pb-6 text-white">Reporting Enhancements</h2>
                  <h2 className="text-white text-[35px] leading-[150%]">
                    We&apos;ve listened to real life businesses and have built the reporting
                    workflows, functionality, and formats that you need, including:
                  </h2>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={5}
                >
                  <Grid item lg={6}>
                    <ul className="mb-4 fancy-bullets white">
                      <li>
                        <p className="text-white medium">PDF and CSV Export options</p>
                      </li>
                    </ul>
                    <Grid item>
                      <img
                        src={ExportOptions}
                        className="reporting-examples"
                        alt="Example of CSV and PDF options for exporting reports"
                      />
                    </Grid>

                    <Grid item></Grid>
                  </Grid>
                  <Grid item lg={6}>
                    <ul className="mb-4 fancy-bullets white">
                      <li>
                        <p className="text-white medium">
                          Auto-calculating running totals for quick reconciliation
                        </p>
                      </li>
                    </ul>
                    <Grid item>
                      <img
                        src={SettlementTotals}
                        className="reporting-examples"
                        alt="Example of settlement batch totals being automatically calculated in final row of settlement list"
                      />
                    </Grid>
                  </Grid>
                  <Grid item lg={6} sx={{ mb: 5 }}>
                    <ul className="mb-4 fancy-bullets white">
                      <li>
                        <p className="text-center text-white medium">
                          Improved settlement reporting for fewer clicks and less load time
                        </p>
                      </li>
                    </ul>
                    <Grid item>
                      <img
                        src={BatchOverview}
                        className="reporting-examples"
                        alt="Example of improved settlement details screen"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={1}></Grid>
            </Grid>
            {/* Streamlined Account Access */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              rowSpacing={5}
              className=""
              sx={{
                pb: 10,
                px: 2
              }}
            >
              <Grid item>
                <Typography variant="h3" color="#23242A" align="center" fontWeight="400">
                  Streamlined Account Access
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={10} lg={3} className="mm-glass-gradient">
                    <Typography
                      className="!font-customFont"
                      fontWeight={300}
                      sx={{
                        fontSize: '1.4rem !important',
                        color: {
                          xs: 'black',
                          lg: 'white'
                        },
                        textAlign: 'center'
                      }}
                    >
                      iQ Pro+ provides functionality and flexibility for merchants who manage
                      multiple locations, businesses or revenue sources through a{'   '}
                      <strong>single sign-on</strong>.
                    </Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10} lg={4} className="ob-sign-in"></Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ width: '75%' }}>
                <h2 className="medium font-bold leading-[150%]">
                  Users can choose how they want to handle multiple locations. iQ Pro+ supports
                  housing all merchant accounts through a single gateway environment or separately
                  with a new quick-toggle action.
                </h2>
              </Grid>
              <Grid item sx={{ width: '75%' }}>
                <h2 className="medium leading-[150%]">
                  The new quick-toggle action allows each merchant account to maintain individual
                  settings, preferences, reports, and more without needing to maintain more than one
                  login.
                </h2>
              </Grid>
              <Grid
                item
                lg={8}
                sx={{
                  width: {
                    xs: '100%',
                    sm: '80%',
                    md: '65%',
                    lg: '30%'
                  }
                }}
              >
                <img
                  src={GatewaySwitch}
                  className="gateway-switch"
                  alt="Example of the improved ability to switch between gateways under a single sign on"
                />
              </Grid>
            </Grid>
            {/* Customer-Driven Development */}
            <Grid container direction="row" justifyContent="center">
              <Grid item lg={1.5}></Grid>
              <Grid item xs={9.5} lg>
                <Grid item className="self-center !mb-10 justify-self-center">
                  <h2 className="!font-semibold large">Customer-Driven Development</h2>
                </Grid>
                <Grid container>
                  <Grid item>
                    <p className="font-medium large">
                      iQ Pro+ is designed with real merchants in mind. Utilizing authentic customer
                      feedback and comprehensive user testing, iQ Pro+ is the latest in payment
                      processing.
                    </p>
                  </Grid>
                  <Grid container direction="row" sx={{ my: 5 }}>
                    <Grid item xs={9} lg={9}>
                      <h2 className="my-6 !text-[2em] font-medium">
                        Users will have the unique opportunity to continue driving innovation.
                      </h2>
                      <p className="!text-[1.8em] font-normal">
                        Through an integrated feedback collector, users can continuously submit
                        comments, observations, and requests on-demand,{' '}
                        <strong>giving you back valuable time</strong>.
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item>
                      <img
                        src={Feedback}
                        className="feedback"
                        alt="Example of iQ Pro+ feedback collector form"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default BetaFeatures;
