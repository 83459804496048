import Footer from '../Footer';
import Header from '../Header';
import MobileNavigation from '../MobileNavigation';
import reportingImage from '../img/ReportingImage.png';
import HeroFeatures from './HeroFeatures';
import background from '../img/softwarePartners.png';
import GetStarted from './GetStarted';
import automaticReceipts from '../img/icon-automatic-payment-receipts.svg';
import search from '../img/icon-search.svg';
import taxCalc from '../img/icon-automatic-tax-calculation.svg';

export default function Reporting() {
  return (
    <>
      <main>
        <Header />
        <MobileNavigation />

        <HeroFeatures
          title="REPORTING"
          primary={
            <>
              Build reports <span className="highlight-pink">your way</span>.
            </>
          }
          secondary="View, reconcile and analyze data from transactions and settlements."
          img={reportingImage}
          imgAlt="reporting view screenshot with labels 'Pending Settlement', 'Today's Transactions', 'Last 30 Days', 'August', and 'Settled'"
        />
        <section className="bg-white">
          <div className="grid grid-cols-1 gap-4 row sm:grid-cols-2 lg:grid-cols-4">
            <article className="card">
              <figure>
                <img src={automaticReceipts} alt="icon for reports from custom filters" />
              </figure>
              <p className="light">Create quick reports from saved custom filters</p>
            </article>
            <article className="card">
              <figure>
                <img src={search} alt="icon for search card" />
              </figure>
              <p className="light">Advanced search features</p>
            </article>
            <article className="card">
              <figure>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="73"
                  height="77"
                  viewBox="0 0 73 77"
                  fill="none"
                >
                  <path
                    d="M53.4783 64.5083V69.6807H19.688V64.5083"
                    stroke="#1C7ABF"
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M36.5834 61.9221L24.1936 45.1117H30.9517V27.0083H42.2151V45.1117H48.9731L36.5834 61.9221Z"
                    stroke="#1C7ABF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M30.9519 21.8359H42.2153"
                    stroke="#1C7ABF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M30.9519 16.6641H42.2153"
                    stroke="#1C7ABF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M71.5 76V20.3966L54.6049 1H3.91943C2.68046 1 1.66675 2.16379 1.66675 3.58621V76"
                    stroke="#1C7ABF"
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M71.4999 20.3967H55.7311C55.1116 20.3967 54.6047 19.8148 54.6047 19.1036V13.9312"
                    stroke="#1C7ABF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </figure>
              <p className="light">Export report data to a PDF or CSV</p>
            </article>
            <article className="card">
              <figure>
                <img src={taxCalc} alt="calculation icon" />
              </figure>
              <p className="light">Automatially calculate running totals</p>
            </article>
          </div>
        </section>
        <section className="bg-white">
          <div
            className="row"
            style={{
              borderRadius: '25px',
              backgroundImage: `url(${background})`,
              backgroundSize: '200% 200%',
              backgroundPosition: 'bottom center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="p-4 mx-auto md:p-8">
              <h3 className="text-white large">How does Reporting work?</h3>
              <p className="text-white medium">iQ Pro+ simplifies payment reconciliation.</p>
              <ul className="my-8 fancy-bullets white">
                <li>
                  <p className="text-white light">
                    Only view the information you need using custom filters.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    Export transaction and settlement data seamlesssly across platforms.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <GetStarted />
      </main>
      <Footer />
    </>
  );
}
