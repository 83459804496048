import logo from './img/iQPro+ Logo.png';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="row">
        <div className="grid justify-center grid-cols-1 gap-4 text-center lg:grid-cols-4">
          <div className="col-span-1">
            <Link to="/">
              <img src={logo} alt="iQPro Logo" />
            </Link>
            <p className="copyright">
              &copy; 2024 iQ Pro
              <br />
              iQ Pro is a registered service mark of BASYS Processing. <br />
              All rights reserved.
            </p>
            <p>
              {' '}
              <Link to="/terms-of-service">Terms of Service</Link> |{' '}
              <a href="https://basyspro.com/privacy-policy/" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </p>
          </div>
          <div className="lg:col-span-2">
            <h2>Features</h2>
            <nav className="grid grid-cols-1 sm:grid-cols-2">
              <div className="test-outline">
                <Link to="/features/terminal">Terminal</Link>
                <Link to="/features/simplePay">Simple Pay</Link>
                <Link to="/features/recurringBilling">Recurring Billing</Link>
                <Link to="/features/reporting">Reporting</Link>
              </div>
              <div className="test-outline">
                <Link to="/features/customers">Customers</Link>
                <Link to="/features/invoicing">Invoicing</Link>
                <Link to="/features/mobileApp">Mobile App</Link>
                <Link to="/features/physicalTerminals">Physical Terminals</Link>
              </div>
            </nav>
          </div>
          <div className="test-outline">
            <h2>Resources</h2>
            <nav>
              <a href="https://developer.basyspro.com/" target="_blank" rel="noreferrer">
                Developer Docs
              </a>
              <a
                href="https://basyspro.com/equipment/qrg/qrg-iqpro/"
                target="_blank"
                rel="noreferrer"
              >
                Quick Reference Guide
              </a>
              <Link to="/support">Frequently Asked Questions</Link>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}
