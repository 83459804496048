import colors from './colors.module.scss';

const light = {
  mode: 'light',
  primary: {
    main: colors.system_white
  },
  secondary: {
    light: colors.blue200,

    main: colors.blue300
  },
  neutrals: {
    main: colors.system_white
  },
  fontColor: {
    main: colors.grey900
  }
};

const dark = {
  mode: 'dark',
  primary: {
    main: colors.grey850
  },
  secondary: {
    light: colors.blue600,
    main: colors.blue450
  },
  neutrals: {
    main: colors.grey900
  },
  fontColor: {
    main: colors.system_white
  }
};

export const getPalette = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light' ? light : dark)
  }
});

export default getPalette;
