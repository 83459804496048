import Terminal from './features/Terminal';
import Landing from './Landing';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Customers from './features/Customers';
import Invoicing from './features/Invoicing';
import SimplePay from './features/SimplePay';
import Reporting from './features/Reporting';
import PhysicalTerminals from './features/PhysicalTerminals';
import RecurringBilling from './features/RecurringBilling';
import Support from './Support';
import BookDemo from './BookDemo';
import MobileApp from './features/MobileApp';
import BetaFeatures from './Onboarding/Features';
import OnboardingTraining from './Onboarding/Training';
import OnboardingConversion from './Onboarding/Conversion';
import OnboardingSignIn from './Onboarding/SignIn';
import { ThemeProvider, /*useMediaQuery,*/ responsiveFontSizes } from '@mui/material';
import { useMemo } from 'react';
import getTheme from './theme';
import TOS from './TOS';
import SignIn from './SignIn';

function App() {
  //const prefersDarkMode = useMediaQuery('prefers-color-scheme: dark');
  const colorMode = 'light';
  let theme = useMemo(() => getTheme(colorMode), [colorMode]);
  theme = responsiveFontSizes(theme);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/features/terminal" element={<Terminal />} />
            <Route path="/features/customers" element={<Customers />} />
            <Route path="/" element={<Landing />} />
            <Route path="/support" element={<Support />} />
            <Route path="/features/invoicing" element={<Invoicing />} />
            <Route path="/features/simplePay" element={<SimplePay />} />
            <Route path="/bookDemo" element={<BookDemo />} />
            <Route path="/features/reporting" element={<Reporting />} />
            <Route path="/features/recurringBilling" element={<RecurringBilling />} />
            <Route path="/features/mobileApp" element={<MobileApp />} />
            <Route path="/features/physicalTerminals" element={<PhysicalTerminals />} />
            <Route path="/onboarding-features" element={<BetaFeatures />} />
            <Route path="/onboarding-training" element={<OnboardingTraining />} />
            <Route path="/onboarding-conversion" element={<OnboardingConversion />} />
            <Route path="/onboarding-sign-in" element={<OnboardingSignIn />} />
            <Route path="/terms-of-service" element={<TOS />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="*" element={<Landing />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
