import Header from './Header';
import background from './img/front.png';
import iconHandshake from './img/icon-handshake.svg';
import iconChat from './img/icon-chat.svg';
import iconSettings from './img/icon-settings.svg';
import iconUserFriendly from './img/icon-user-friendly.svg';
import backgroundSoftware from './img/softwarePartners.png';
import betterIsBetterImage from './img/img-hand-holding-cc.png';
import streamlinedImage from './img/img-login-screen.png';
import frontImage from './img/frontImage.png';
import MobileNavigation from './MobileNavigation';
import Footer from './Footer';
import { useRef, useState } from 'react';
import { useGSAP } from '@gsap/react';
import { Select, Input, InputNumber, Form, Alert } from 'antd';
import DrawSVGPlugin from '@gsap/business/DrawSVGPlugin';
import gsap from '@gsap/business';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './App.css';

gsap.registerPlugin(useGSAP);

gsap.registerPlugin(DrawSVGPlugin);

export default function Landing() {
  const container = useRef();
  const [form] = Form.useForm();
  const [helpText, setHelpText] = useState(
    'I’m new to Basys. I want to learn about accepting cards with iQ Pro+.'
  );
  const [alert, throwAlert] = useState(<></>);

  const handleChange = (value) => {
    if (value === 'New Merchant') {
      setHelpText('I’m new to Basys. I want to learn about accepting cards with iQ Pro+.');
    } else if (value === 'Current Merchant') {
      setHelpText('I’m a current Basys customer and I want to learn more about iQ Pro+.');
    } else if (value === 'Integrations Partner') {
      setHelpText('I want to learn about integrating iQ Pro+ with my company’s software.');
    } else {
      setHelpText(' ');
    }
  };

  const [validateStatusPhone, setValidateStatusPhone] = useState('');

  const onChangePhone = () => {
    const value = String(document.getElementById('pnumber').value);

    const result = /^\d{10}$/.test(value);
    if (result) {
      setValidateStatusPhone('success');
      document.getElementById('submitRequest').disabled = false;
    } else {
      setValidateStatusPhone('error');
    }
  };

  const onFinish = (values) => {
    document.getElementById('submitRequest').disabled = true;
    console.log(values);
    const value = String(document.getElementById('pnumber').value);
    const result = /^\d{10}$/.test(value);
    if (!result) {
      throw new Error('The phone number is not 10 digits');
    }
    console.log('Email sending...');
    setValidateStatusPhone('');
    let date = new Date();
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    const userDate = date.toISOString().split('T')[0];
    values.date = userDate;
    axios
      .post(`${process.env.REACT_APP_EMAIL_FUNCTION_URL}/api/sendleademail`, values)
      .then((res) => {
        if (res.status === 200) {
          console.log('success');
          throwAlert(
            <Alert
              message="Thank you for reaching out."
              description="Your request will be sent to one of our representatives."
              type="success"
              showIcon
              closable
            />
          );
          form.resetFields();
        }
      })
      .catch(function (error) {
        console.error(error);
        document.getElementById('submitRequest').disabled = false;
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  useGSAP(
    () => {
      // let arrowAnimation = gsap.to('#arrowRequest', {
      //   rotation: 360,
      //   x: 10,
      //   paused: true
      // });
      // let softwareDemo = gsap.to('#arrowSoftwareDemo', {
      //   rotation: 360,
      //   x: 10,
      //   paused: true
      // });
      // let softwareDocs = gsap.to('#arrowSoftwareDocs', {
      //   rotation: 360,
      //   x: 10,
      //   paused: true
      // });
      // let arrowLearn = gsap.to('#arrowLearn', { rotation: 360, x: 10, paused: true });
      // let submitArrow = gsap.to('#submitArrow', {
      //   rotation: 360,
      //   x: 10,
      //   paused: true
      // });
      // let button = document.getElementById('buttonRequest');
      // let buttonSoftwareDemo = document.getElementById('buttonSoftwareDemo');
      // let buttonSoftwareDocs = document.getElementById('buttonSoftwareDocs');
      // let buttonLearn = document.getElementById('buttonLearn');
      // let formRequest = document.getElementById('submitRequest');
      // button.addEventListener('mouseenter', () => arrowAnimation.play());
      // button.addEventListener('mouseleave', () => arrowAnimation.reverse());
      // buttonSoftwareDemo.addEventListener('mouseenter', () => softwareDemo.play());
      // buttonSoftwareDemo.addEventListener('mouseleave', () => softwareDemo.reverse());
      // buttonSoftwareDocs.addEventListener('mouseenter', () => softwareDocs.play());
      // buttonSoftwareDocs.addEventListener('mouseleave', () => softwareDocs.reverse());
      // buttonLearn.addEventListener('mouseenter', () => arrowLearn.play());
      // buttonLearn.addEventListener('mouseleave', () => arrowLearn.reverse());
      // formRequest.addEventListener('mouseenter', () => submitArrow.play());
      // formRequest.addEventListener('mouseleave', () => submitArrow.reverse());
    },
    { scope: container }
  );
  return (
    <>
      <main ref={container}>
        <MobileNavigation />
        <section
          className=""
          style={{
            background: `url(${background}) -1.731px / 100% 100% no-repeat`
          }}
        >
          <Header />

          <div className="row hero-row">
            <article className="hero-left">
              <h1>
                Accept Payments.
                <span className="oblique">Quickly. Simply. Securely.</span>
              </h1>
            </article>
            <figure className="cols-span-2">
              <img src={frontImage} alt="iQ Pro+ on desktop and mobile" />
            </figure>
          </div>
        </section>
        {/* TIME MONEY HASSLE */}
        <section className="bg-white">
          <div className="row">
            <div className="grid justify-center grid-cols-1 text-center">
              <p className="text-3xl text-center">
                <span className="highlight-pink">iQ Pro+</span> is built to save you time, money
                and hassle.
              </p>
            </div>
            <div className="grid grid-cols-1">
              <p className="text-center medium light">
                Focus on your business and we’ll handle the rest.
                <a
                  href="#requestDemo"
                  className="button button-primary button-inline has-arrow has-arrow-white"
                  id="buttonRequest"
                >
                  Book a demo today!
                </a>
              </p>
            </div>
          </div>
        </section>

        {/* FEATURE CARDS */}
        <section className="bg-white">
          <div className="grid grid-cols-1 gap-4 row sm:grid-cols-2 lg:grid-cols-4">
            <article className="card linked">
              <h2 className="has-icon terminal">Terminal</h2>
              <p className="light">Accept payments online</p>
              <Link to="/features/terminal">
                <p className="learn-more">Learn More</p>
              </Link>
            </article>
            <article className="card linked">
              <h2 className="has-icon invoices">Invoicing</h2>
              <p className="light">Send customers a click-to-pay link via email or text</p>

              <Link to="/features/invoicing">
                <p className="learn-more">Learn More</p>
              </Link>
            </article>
            <article className="card linked">
              <h2 className="has-icon simple-pay">Simple Pay</h2>
              <p className="light">Secure hosted payment page</p>
              <Link to="/features/simplepay">
                <p className="learn-more">Learn More</p>
              </Link>
            </article>
            <article className="card linked">
              <h2 className="has-icon customers">Customers</h2>
              <p className="light">Securely store customer payment information</p>
              <Link to="/features/customers">
                <p className="learn-more">Learn More</p>
              </Link>
            </article>
          </div>
        </section>
        {/* SOFTWARE PARTNERS NEW */}
        <section className="bg-white">
          <div
            className="row"
            style={{
              borderRadius: '25px',
              backgroundImage: `url(${backgroundSoftware})`,
              backgroundSize: '200% 200%',
              backgroundPosition: 'bottom center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="p-4 mx-auto md:p-8">
              <h2 className="text-white eyebrow">Software Partners</h2>
              <h3 className="text-white large">
                Adaptable, easy-to-integrate APIs that work seamlessly within your platform.
              </h3>
              <div className="grid grid-cols-1 gap-8 pt-8 pb-16 sm:grid-cols-2">
                <div className="grid grid-cols-1 gap-2 px-4 border-l border-white">
                  <img className="mx-0" src={iconHandshake} alt="Description" />
                  <p className="mb-0 text-white light">
                    A single, dedicated point of contact for each client
                  </p>
                </div>
                <div className="grid grid-cols-1 gap-2 px-4 border-l border-white">
                  <img className="mx-0" src={iconChat} alt="Description" />
                  <p className="mb-0 text-white light">
                    Frequent interaction with your team throughout the integration process
                  </p>
                </div>
                <div className="grid grid-cols-1 gap-2 px-4 border-l border-white">
                  <img className="mx-0" src={iconSettings} alt="Description" />
                  <p className="mb-0 text-white light">
                    Gateway customizations to meet the needs of your software
                  </p>
                </div>
                <div className="grid grid-cols-1 gap-2 px-4 border-l border-white">
                  <img className="mx-0" src={iconUserFriendly} alt="Description" />
                  <p className="mb-0 text-white light">
                    Gateway customizations to meet the needs of your software
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1">
                <p className="text-center text-white medium light">
                  Learn more about partnering with us.
                  <a
                    className="button button-primary-dark button-inline has-arrow has-arrow-white"
                    href="#requestDemo"
                  >
                    Book a Demo
                  </a>
                  <a
                    className="button button-primary-dark button-inline has-arrow has-arrow-white"
                    href="https://developer.basyspro.com/"
                    target="blank"
                  >
                    Dev Docs
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* BETTER IS BETTER */}
        <section className="bg-white">
          <div className="row">
            <div className="grid grid-cols-1">
              <h3 className="large">
                Better is <span className="text-[#C0F] font-semibold">Better</span>.
              </h3>
              <p>
                iQ Pro+ gives you the best in payment technology along with the unparalleled service
                Basys is known for.
              </p>
            </div>

            <section className="grid items-center grid-cols-1 gap-4 sm:grid-cols-2">
              <figure className="w-full max-w-1/2">
                <img src={betterIsBetterImage} alt="Hand holding credit card"></img>
              </figure>
              <ul className="my-8 fancy-bullets">
                <li>
                  <p className="light">
                    Our payment solutions are customized to meet the unique needs of your business.
                  </p>
                </li>
                <li>
                  <p className="light">
                    <span className="highlight-purple">We’re passionate about people. </span>
                    iQ Pro+ utilizes real customer feedback and comprehensive user testing to bring
                    you the latest in payment solutions.
                  </p>
                </li>
                <li>
                  <p className="light">
                    Stay ahead of payment security with Basys’ Proactive PCI approach—making the
                    process simple.
                  </p>
                </li>
                <li>
                  <p className="light">
                    We’re ready to tackle any challenge. Our talented in-house support team answers{' '}
                    <span className="highlight-purple">99% of calls immediately.</span>
                  </p>
                </li>
              </ul>
            </section>
          </div>
        </section>

        {/* STREAMLINED ACCOUNT ACCESS */}
        <section className="bg-white">
          <div className="row">
            <div className="grid items-center grid-cols-1 gap-4 sm:grid-cols-2">
              <article>
                <h3 className="large">Streamlined account access.</h3>
                <p className="light">
                  iQ Pro+ provides functionality and flexibility for merchants who manage multiple
                  locations, businesses or revenue sources all in one place.
                </p>
                <p className="light">
                  The quick-toggle action allows each merchant account to maintain individual
                  settings, preferences and reports without managing more than one login.
                </p>
              </article>
              <figure className="w-full max-w-1/2">
                <img src={streamlinedImage} alt="iQ Pro+ sign in" />
              </figure>
            </div>
          </div>
        </section>

        {/* PROCESSING FEES */}
        <article className="bg-white">
          <div className="row row-md">
            <h3 className="text-center large">We’re transparent about our processing fees.</h3>
            <p className="text-center light">
              For merchants processing Business to Business or Business to Government transactions,
              processing with Level II and III data can achieve savings on credit card interchange
              costs.
            </p>
            <p className="my-8 text-center large light">
              <span className="highlight-pink">Save over 1% </span> on some cards by utilizing this
              data.
            </p>
            <a
              href="#requestDemo"
              className="button button-primary center has-arrow has-arrow-white"
              id="buttonLearn"
            >
              Learn More
            </a>
          </div>
        </article>

        {/* FORM */}
        <div className="bg-white">
          <div className="row row-sm">
            <section
              id="requestDemo"
              className="grid grid-cols-1 p-8 md:p-16"
              style={{
                borderRadius: '25px',
                backgroundImage: `url(${backgroundSoftware})`,
                backgroundSize: '200% 200%',
                backgroundPosition: 'bottom center',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <h2 className="text-center text-white large">Ready to get started?</h2>
              <Form
                initialValues={{ leadType: 'New Merchant' }}
                id="form"
                form={form}
                className="grid grid-cols-1 gap-1"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <div className="grid items-center grid-cols-1 gap-2">
                  <p className="text-white label">I am a(n)...</p>
                  <label htmlFor="role" className="text-white label">
                    Select an option
                  </label>
                  <Form.Item name="leadType" className="w-full mb-0">
                    <Select
                      size="large"
                      id="role"
                      name="leadType"
                      onChange={handleChange}
                      style={{
                        width: '100%',
                        alignSelf: 'flex-start'
                      }}
                      options={[
                        {
                          value: 'New Merchant',
                          label: 'New Merchant'
                        },
                        {
                          value: 'Current Merchant',
                          label: 'Current Merchant'
                        },
                        {
                          value: 'Integrations Partner',
                          label: 'Integrations Partner'
                        }
                      ]}
                    />
                  </Form.Item>
                  <p className="mt-2 mb-4 text-white small light">{helpText}</p>
                </div>
                <div className="grid grid-cols-1 lg:gap-8 lg:grid-cols-2">
                  <div>
                    <label htmlFor="fname" className="text-white label">
                      First Name
                    </label>
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: 'Please add first name'
                        }
                      ]}
                    >
                      <Input id="fname" name="firstName" size="large" />
                    </Form.Item>
                  </div>
                  <div>
                    <label htmlFor="lname" className="text-white label">
                      Last Name
                    </label>
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: 'Please add last name'
                        }
                      ]}
                    >
                      <Input id="lname" name="lastName" size="large" />
                    </Form.Item>
                  </div>
                </div>
                <div className="grid grid-cols-1">
                  <label htmlFor="cname" className="text-white label">
                    Company Name
                  </label>
                  <Form.Item
                    name="companyName"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: 'Please add company name'
                      }
                    ]}
                  >
                    <Input id="cname" name="companyName" size="large" />
                  </Form.Item>
                </div>
                <div className="grid items-center grid-cols-1 lg:gap-8 lg:grid-cols-2">
                  <div>
                    <label htmlFor="email" className="text-white label">
                      Email
                    </label>
                    <Form.Item
                      name="email"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          type: 'email',
                          required: true,
                          message: 'Please use a valid email'
                        }
                      ]}
                    >
                      <Input id="email" name="email" size="large" />
                    </Form.Item>
                  </div>
                  <div>
                    <label htmlFor="pnumber" className="text-white label">
                      Phone Number
                    </label>
                    <Form.Item
                      name="phoneNumber"
                      validateStatus={validateStatusPhone}
                      hasFeedback
                      help="Please add a 10 digit phone number, do not enter dashes"
                      rules={[
                        {
                          required: true,
                          message: 'Please add a valid phone number'
                        }
                      ]}
                    >
                      <InputNumber
                        id="pnumber"
                        name="phoneNumber"
                        placeholder="##########"
                        size="large"
                        className="w-full"
                        onBlur={onChangePhone}
                        maxLength={10}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="grid items-center justify-center grid-cols-1">
                  <Form.Item className="mb-0">
                    <button
                      type="submit"
                      id="submitRequest"
                      className="button button-primary-dark has-arrow has-arrow-left"
                    >
                      Submit
                    </button>
                  </Form.Item>
                </div>
              </Form>
              {alert}
            </section>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}
