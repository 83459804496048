import logo from './img/iQPro+ Logo.png';
import './App.css';
import { MdOutlineMenu } from 'react-icons/md';
import { useState } from 'react';
import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from '@gsap/business';
import DrawSVGPlugin from '@gsap/business/DrawSVGPlugin';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';

gsap.registerPlugin(useGSAP);

gsap.registerPlugin(DrawSVGPlugin);

export default function MobileNavigation() {
  const container = useRef();
  const [click, setClick] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [childrenDrawerTwo, setChildrenDrawerTwo] = useState(false);
  const [childrenDrawerThree, setChildrenDrawerThree] = useState(false);
  const arrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="31" viewBox="0 0 37 31" fill="none">
      <path
        d="M36.1528 15.4595C36.1528 15.7655 36.0313 16.059 35.8149 16.2754C35.5985 16.4918 35.305 16.6133 34.999 16.6133H3.93937L15.8144 28.4903C15.9277 28.5959 16.0187 28.7233 16.0817 28.8648C16.1448 29.0064 16.1787 29.1592 16.1814 29.3141C16.1842 29.469 16.1557 29.6229 16.0976 29.7666C16.0396 29.9102 15.9532 30.0408 15.8437 30.1503C15.7341 30.2599 15.6036 30.3463 15.4599 30.4043C15.3162 30.4623 15.1623 30.4908 15.0074 30.4881C14.8525 30.4854 14.6997 30.4515 14.5582 30.3884C14.4166 30.3253 14.2892 30.2344 14.1836 30.121L0.337446 16.2749C0.121368 16.0585 0 15.7653 0 15.4595C0 15.1537 0.121368 14.8605 0.337446 14.6441L14.1836 0.797961C14.4023 0.594145 14.6916 0.483187 14.9906 0.488461C15.2895 0.493735 15.5747 0.61483 15.7861 0.826235C15.9975 1.03764 16.1186 1.32285 16.1239 1.62177C16.1291 1.9207 16.0182 2.21 15.8144 2.42873L3.93937 14.3057H34.999C35.305 14.3057 35.5985 14.4272 35.8149 14.6436C36.0313 14.86 36.1528 15.1535 36.1528 15.4595Z"
        fill="#000066"
      />
    </svg>
  );
  const close = (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
      <path
        d="M29.6328 28.4903C29.7461 28.5959 29.837 28.7233 29.9001 28.8648C29.9632 29.0064 29.9971 29.1592 29.9998 29.3141C30.0026 29.469 29.9741 29.6229 29.916 29.7666C29.858 29.9102 29.7716 30.0408 29.662 30.1503C29.5525 30.2599 29.422 30.3463 29.2783 30.4043C29.1346 30.4623 28.9807 30.4908 28.8258 30.4881C28.6709 30.4854 28.5181 30.4515 28.3765 30.3884C28.235 30.3253 28.1076 30.2344 28.002 30.121L14.9712 17.0922L1.94045 30.121C1.72172 30.3249 1.43242 30.4358 1.13349 30.4305C0.834566 30.4253 0.549358 30.3042 0.337954 30.0928C0.126549 29.8814 0.00545394 29.5962 0.000179765 29.2972C-0.00509441 28.9983 0.105864 28.709 0.309679 28.4903L13.3385 15.4595L0.309679 2.42873C0.105864 2.21 -0.00509441 1.9207 0.000179765 1.62177C0.00545394 1.32285 0.126549 1.03764 0.337954 0.826235C0.549358 0.61483 0.834566 0.493735 1.13349 0.488461C1.43242 0.483187 1.72172 0.594145 1.94045 0.797961L14.9712 13.8268L28.002 0.797961C28.2207 0.594145 28.51 0.483187 28.8089 0.488461C29.1079 0.493735 29.3931 0.61483 29.6045 0.826235C29.8159 1.03764 29.937 1.32285 29.9423 1.62177C29.9475 1.9207 29.8366 2.21 29.6328 2.42873L16.6039 15.4595L29.6328 28.4903Z"
        fill="#000066"
      />
    </svg>
  );
  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };
  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };
  const showChildrenDrawerTwo = () => {
    setChildrenDrawerTwo(true);
  };
  const onChildrenDrawerCloseTwo = () => {
    setChildrenDrawerTwo(false);
  };
  const showChildrenDrawerThree = () => {
    setChildrenDrawerThree(true);
  };
  const onChildrenDrawerCloseThree = () => {
    setChildrenDrawerThree(false);
  };

  const Hamburger = (
    <MdOutlineMenu
      className="self-center HamburgerMenu justify-self-center"
      size="36px"
      color="black"
      onClick={() => {
        setClick(!click);
      }}
    />
  );
  useGSAP(
    () => {
      // let arrowAnimationMobile = gsap.to('#arrowTopNav', {
      //   rotation: 360,
      //   x: 10,
      //   paused: true
      // });
      // let buttonTopMobile = document.getElementById('navTopButton');
      // buttonTopMobile.addEventListener('mouseenter', () => arrowAnimationMobile.play());
      // buttonTopMobile.addEventListener('mouseleave', () => arrowAnimationMobile.reverse());
    },
    { scope: container }
  );
  return (
    <section className="min-[1000px]:hidden">
      <div className="row nav-row mobile-nav-row">
        <header ref={container} className="mobile-nav">
          {Hamburger}

          <a className="mobile-subnav-logo" href="/">
            <img src={logo} alt="iQ Pro+ Logo" />
          </a>

          <Drawer
            width="100%"
            onClose={() => {
              setClick(!click);
            }}
            open={click}
            placement="left"
            closeIcon={close}
            size="36px"
            extra={
              <Link to="/" className="">
                <img className="mobile-subnav-logo" src={logo} alt="iQ Pro+ Logo" />
              </Link>
            }
          >
            {/* features*/}
            <section className="subnav-wrapper">
              <button onClick={showChildrenDrawer} className="mobile-subnav has-arrow">
                Features
              </button>
              <Drawer
                width="100%"
                onClose={onChildrenDrawerClose}
                closeIcon={arrow}
                size="36px"
                open={childrenDrawer}
                placement="left"
                extra={
                  <Link to="/" className="">
                    <img className="mobile-subnav-logo" src={logo} alt="iQ Pro+ Logo" />
                  </Link>
                }
              >
                <nav className="subnav-wrapper">
                  <Link to="/features/terminal" className="mobile-subnav has-arrow">
                    Virtual Terminal
                  </Link>
                  <Link to="/features/simplePay" className="mobile-subnav has-arrow">
                    Simple Pay
                  </Link>
                  <Link to="/features/mobileApp" className="mobile-subnav has-arrow">
                    Mobile App
                  </Link>
                  <Link to="/features/invoicing" className="mobile-subnav has-arrow">
                    Invoicing
                  </Link>
                  <Link to="/features/recurringBilling" className="mobile-subnav has-arrow">
                    Recurring Billing
                  </Link>
                  <Link to="/features/reporting" className="mobile-subnav has-arrow">
                    Reporting
                  </Link>
                  <Link to="/features/physicalTerminals" className="mobile-subnav has-arrow">
                    Physical Terminals
                  </Link>
                </nav>
              </Drawer>

              <button onClick={showChildrenDrawerTwo} className="mobile-subnav has-arrow">
                Resources
              </button>

              {/* resources subnav drawer*/}
              <Drawer
                width="100%"
                onClose={onChildrenDrawerCloseTwo}
                closeIcon={arrow}
                size="36px"
                open={childrenDrawerTwo}
                placement="left"
                extra={
                  <Link to="/" className="">
                    <img className="mobile-subnav-logo" src={logo} alt="iQ Pro+ Logo" />
                  </Link>
                }
              >
                <nav className="subnav-wrapper">
                  <a
                    href="https://developer.basyspro.com/"
                    className="mobile-subnav has-arrow"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Developer Docs
                  </a>
                  <a
                    href="https://basyspro.com/equipment/qrg/qrg-iqpro/"
                    className="mobile-subnav has-arrow"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Quick Reference Guide
                  </a>
                </nav>
              </Drawer>

              {/* support */}
              <a href="/support" className="mobile-subnav has-arrow">
                Support
              </a>

              {/* login */}
              <button onClick={showChildrenDrawerThree} className="mobile-subnav has-arrow">
                Login
              </button>

              {/* login subnav*/}
              <Drawer
                width="100%"
                onClose={onChildrenDrawerCloseThree}
                closeIcon={arrow}
                size="36px"
                open={childrenDrawerThree}
                placement="left"
                extra={
                  <Link to="/" className="">
                    <img className="mobile-subnav-logo" src={logo} alt="iQ Pro+ Logo" />
                  </Link>
                }
              >
                <nav className="subnav-wrapper">
                  <a
                    href="https://basyspro.net/Default.aspx"
                    className="mobile-subnav has-arrow"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Basys IQ
                  </a>
                  <a
                    href="https://app.basysiqpro.com/auth"
                    className="mobile-subnav has-arrow"
                    target="_blank"
                    rel="noreferrer"
                  >
                    iQ Pro
                  </a>
                  <a
                    href="https://app.iqpro.com"
                    className="mobile-subnav has-arrow"
                    target="_blank"
                    rel="noreferrer"
                  >
                    iQ Pro+
                  </a>
                </nav>
              </Drawer>

              {/* book demo link */}
              <Link
                to="/bookDemo"
                className="w-full max-w-sm mt-8 button button-primary has-arrow has-arrow-white"
                id="navTopButtonNormal"
              >
                Contact Sales
              </Link>
            </section>
          </Drawer>
        </header>
      </div>
    </section>
  );
}
